const x = `<head>
<style>
@media only print {
@font-face {
  font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-style: normal;
  font-size: 16px;
    }
}
body {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  max-width: 500px;
  font-size: 16px;
}
h4 {
  line-height: 1;
  margin: 3rem 0px 0px; 
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}
.row {
  display: flex;
  width: 100%;
  line-height: 20px;
}
.col{
  width: 50%;
}
.right {
  display: flex;
  justify-content: end;
}
.header {
margin-top: 16px; 
border: 1px solid gray;
width: 100%;
}
.restaurant-name {
  font-size: 26px;
  margin-bottom:8px;
  font-weight: 800;
}
.data {
  margin-top: 4px;
}
.container {
}
.summary-data {
  margin-top: 4px;
}
.two-headers{
  display: flex;
  width:100%;
}
.hr-new {
  border-top: 1px dashed gray;
}
.hr-td {
  border-top: 1px dashed gray;
}
td {
  border-style: hidden;
  font-size: 14px;
  font-weight: 600;
}
.price {
  text-align: right;
    display: grid;
    align-items: start;
    }
tr {
  height: 40px;
}
span {
  font-size: 12px;
  font-weight: 400;
}
  .row2{display:none;}
</style>
</head>
<body onload="this.focus({preventScroll:false}); this.print();">
<h4>
  <div class="restaurant-name">{{RestaurantName}}</div>
<div>Branch name : {{BranchName}}</div>
  <div>Order no : {{Id}}</div>
  <div>{#formatDate('{{CreationTime}}')/}<br><br></div></h4>
  <div>
<div class="row">
    <div class="col">
      {{Name}}
      </div><div class="col right">
      إسم الزبون
  </div> </div>
<div class="row"><div class="col">
{{PhoneNumber}}
</div><div class="col right">
  رقم الهاتف
</div></div>
<div class="row"><div class="col">
{{BranshAddress}}
</div><div class="col right">
  المنطقة
</div></div>
<div class="row"><div class="col">
{{StreetNo}}
</div><div class="col right">
  إسم الشارع
</div></div>
<div class="row"><div class="col">
{{BuildingNo}}
</div><div class="col right">
  رقم البناية
</div></div>
<div class="row"><div class="col">
{{AddressDetail}}
</div><div class="col right">
  رقم الطابق
</div></div>
<div class="row"><div class="col">
{{Description}}
</div><div class="col right">
  ملاحظات
</div></div>
</div>
<div class="container">
  <div class="header">
    Order notes
  </div>
  <div class="data">
    {{Description}}
  </div>
  </div>
  <div class="header">
    <div class="row"><div class="col">
Item
</div><div class="col right">
  Price 
</div></div>
  </div>
<table style="border-collapse: collapse; width: 100%; height: 36px;">
        <colgroup>
            <col style="width: 80%;">
            <col style="width: 20%;">
        </colgroup>
  <tbody>
    <tr id="OrderTransations">
      <td>{{OrderTransations.Qty}} × {{OrderTransations.Name}}
        <span> 
        <table style="width: 100%;">
          <td>{{OrderTransations.Addon}}</td>
        </table>
        </span>
      </td>
      <td class="price">{#ToFixed({{OrderTransations.TotalPrice}}*{{OrderTransations.Qty}})/}</td>
      </tr> 
  </tbody>
</table>
    <hr class="hr-new">
     <div class="header">
    <div class="row"><div class="col">
Order type
</div><div class="col right">
  Delivery price 
</div></div>
  </div>
    <div class="row data">
    <div class="col">
      {{DeliveryType}}
      </div><div class="col right">
      {#ToFixed({{DeliveryPrice}})/} JOD
  </div> </div>
    <div class="header">
      Order summary
    </div>
  <div class="summary-data data">
    <div>
      SubTotal: {#ToFixed({{TotalAmount}}-{{DeliveryPrice}})/} JOD
    </div>
    <div>
      Total: {#ToFixed({{TotalAmount}})/} JOD
    </div>
  </div>
</body>
`
export default x
